<template>
  <div>
    <div id="button-head">
      <button
        class="back btn btn-sm btn-outline-secondary shadow-none"
        @click="back"
      >
        <i class="bi bi-arrow-left"></i> назад
      </button>
      <button
        class="btn btn-sm btn-primary"
        :disabled="saveButtonDisabled"
        @click.prevent="onSubmit"
      >
        {{ textButtonSave }}
      </button>
    </div>
    <div class="repairs-wrapper form-row" id="wrapper">
      <div class="repairs-wrapper-item col-md-6 left">
        <Selected
          :key="formKey"
          title="кликните для выбора инструмента"
          :setChangeInstr="true"
          @updateCodInstr="setCodInstr"
          @updateNameInstr="setNameInstr"
          :invalid-class="validationsField[0].error ? 'is-invalid-custom' : ''"
        />
        <form id="newRepairForm" :key="formKey + 1">
          <div class="form-row">
            <div class="form-group col-md-4">
              <input
                id="serialNumberGroup"
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                placeholder="буквы серийного номера"
                v-model.trim="dataInstr.serialNumberGroup"
                @input="translit"
                :class="validationsField[1].error ? 'is-invalid' : ''"
                :disabled="disabled_serialNumberGroup"
              />
            </div>
            <div class="form-group col-md-4">
              <input
                id="serialNumber"
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                placeholder="цифры серийного номера"
                v-model.trim="dataInstr.serialNumber"
                @input="translit2"
                :class="validationsField[2].error ? 'is-invalid' : ''"
              />
            </div>
            <div class="form-group col-md-4">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input shadow-none"
                  id="customCheck1"
                  v-model="dataInstr.notSerialNumber"
                  @input="disabledCheckbox"
                />
                <label class="custom-control-label" for="customCheck1"
                  >серийный номер отсутствует</label
                >
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-2">
              <div
                class="custom-control custom-checkbox shadow-none form-group"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck2"
                  v-model="dataInstr.predtorg"
                  @input="dateSaleDisable"
                />
                <label class="custom-control-label" for="customCheck2"
                  >пред торг</label
                >
              </div>
            </div>
            <div class="form-group col-md-5">
              <DatePicker
                v-model="dataInstr.dateSale"
                :disabled="dateSaleDisabled"
                :input-attr="dateSaleInputAttr"
                valueType="YYYY-MM-DD"
                title-format="DD.MM.YYYY"
              ></DatePicker>
            </div>
            <div class="form-group col-md-5">
              <DatePicker
                v-model="dataInstr.dateRepair"
                :input-attr="dateRepairInputAttr"
                valueType="YYYY-MM-DD"
              ></DatePicker>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                placeholder="продавец"
                v-model="dataInstr.seller"
                :class="validationsField[6].error ? 'is-invalid' : ''"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <textarea
                class="form-control form-control-sm input-form shadow-none"
                rows="3"
                placeholder="дефект"
                v-model="dataInstr.defect"
                :class="validationsField[5].error ? 'is-invalid' : ''"
              ></textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 text-left">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  checked
                  @input="radio('repair')"
                />
                <label class="form-check-label" for="inlineRadio1"
                  >ремонт с использованием зч</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  @input="radio('noRepair')"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >отказ от ремонта</label
                >
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  @input="radio('diagnostiks')"
                />
                <label class="form-check-label" for="inlineRadio3"
                  >диагностика или ремонт без зч</label
                >
              </div>
            </div>
          </div>
          <div class="form-row">
            <div
              class="form-group col-md-12"
              v-if="
                dataInstr.typeOfRepair === 'repair' ||
                  dataInstr.typeOfRepair === 'diagnostiks'
              "
            >
              <textarea
                class="form-control form-control-sm input-form shadow-none"
                rows="3"
                :placeholder="
                  dataInstr.typeOfRepair === 'repair'
                    ? 'проведенные работы или работы которые нужно будет произвести'
                    : 'результат диагностики или проведенные работы при ремонте без зч'
                "
                v-model="dataInstr.workCarried"
                :class="validationsField[11].error ? 'is-invalid' : ''"
              ></textarea>
            </div>
            <div
              v-else-if="dataInstr.typeOfRepair === 'noRepair'"
              class="form-group col-md-12"
            >
              <select
                v-model="dataInstr.noRepairType"
                class="form-control form-control-sm shadow-none"
              >
                <option value="1">отказ от ремонта в течении 15 дней</option>
                <option value="2"
                  >отказ от ремонта в течении года только Леруа, Касторама и ОБИ
                </option>
                <option value="3">отсутствие запчастей для ремонта</option>
                <option value="4">другое</option>
              </select>
            </div>
          </div>
          <div v-if="dataInstr.noRepairType === '4'" class="form-row">
            <div class="form-group col-md-12">
              <textarea
                class="form-control form-control-sm input-form shadow-none"
                rows="3"
                placeholder="укажите причину отказа от ремонта"
                v-model="dataInstr.noRepairTypeOther"
                :class="validationsField[12].error ? 'is-invalid' : ''"
              ></textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <input
                type="text"
                class="form-control form-control-sm input-form shadow-none"
                placeholder="ваш внутренний номер квитанции"
                v-model="dataInstr.receiptNumber"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4 text-center category">
              категория ремонта
            </div>
            <div class="form-group col-md-2">
              <select
                v-model="dataInstr.repairCategory"
                class="form-control form-control-sm shadow-none"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <small class="form-text text-muted"
                >при загрузке фотографий Вы можете использовать один или
                несколько файлов по каждому пункту
              </small>
              <small class="form-text text-muted">
                <span class="formSpan"
                  >файлы должны быть картинками в формате jpeg, jpg, jfif, png
                  или gif</span
                >
              </small>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <UploadFile
                :key="formKey + 2"
                @updateFormDataFoto="setFormDataLabelFoto"
                title="фото шильдика с серийным номером"
                setId="labelFoto"
                :errorClass="validationsField[7].error ? 'is-invalid' : ''"
                :typeFile="typeFileFoto"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <UploadFile
                :key="formKey + 3"
                @updateFormDataFoto="setFormDataTicketFoto"
                title="фото гарантийного талона в паспорте изделия"
                setId="ticketFoto"
                :errorClass="validationsField[8].error ? 'is-invalid' : ''"
                :typeFile="typeFileFoto"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <UploadFile
                :key="formKey"
                @updateFormDataFoto="setFormDataViewFoto"
                title="фото внешнего вида изделия"
                setId="viewFoto"
                :errorClass="validationsField[9].error ? 'is-invalid' : ''"
                :typeFile="typeFileFoto"
              />
            </div>
          </div>
          <div class="form-row" v-if="!dataInstr.noPhotoDefectDetailFoto">
            <div class="form-group col-md-12">
              <UploadFile
                :key="formKey + 4"
                @updateFormDataFoto="setFormDataDefectDetailFoto"
                title="фото дефекта, дефектных деталей"
                setId="defectDetailFoto"
                :errorClass="validationsField[10].error ? 'is-invalid' : ''"
                :typeFile="typeFileFoto"
              />
            </div>
          </div>
          <div v-else />
          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="custom-control custom-checkbox shadow-none">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck4"
                  v-model="dataInstr.noPhotoDefectDetailFoto"
                  @input="getNoPhotoDefectDetailFoto"
                />
                <label class="custom-control-label" for="customCheck4"
                  >невозможно сфотографировать дефект или дефектные детали
                  (только если инструмент не разбирался)</label
                >
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <small class="form-text text-muted">
                <span class="formSpan">
                  дополнительные материалы должны быть в формате jpeg, jpg,
                  jfif, png, gif, avi, mp4, mov, mp3, xls, xlsx, pdf, docx, doc
                </span>
              </small>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <UploadFile
                :key="formKey"
                @updateFormDataFoto="setFormDataAdditional"
                title="дополнительные материалы (фото, видео, документы)"
                setId="dataAdditionalFoto"
                :typeFile="typeFileAdditional"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <textarea
                class="form-control form-control-sm input-form shadow-none"
                rows="3"
                placeholder="комментарий"
                v-model="dataInstr.comment"
              ></textarea>
            </div>
          </div>
        </form>
        <ModalMessage
          :key="formKey + 5"
          v-if="messageVisible"
          :message="message"
          @hideMessage="hideMessage"
        />
        <div v-else></div>
      </div>
      <div v-if="visibleZap" class="repairs-wrapper-item right col-md-6">
        <SelectZapForRepair
          :dataZap="dataZap"
          :zapForRepair="dataInstr.zapForRepair"
          :downloadPercentage="downloadPercentage"
          :disabled="dataZap.loading"
          @addZapForRepair="addZapForRepair"
          :oldzapvisible="true"
          :newZapTitle="`заказать запчасти для этого ремонта`"
          :cenaVisible="false"
        />
        <div v-if="!no_photo_sheme && shemeFotoUrl" class="sheme-wrapper">
          <div class="sheme" v-for="url in shemeFotoUrl.name" :key="url.id">
            <h6 class="text-center">
              {{ url.name }}
              <a href="#" @click.prevent="getVisibleSheme(url.name)"
                >показать/скрыть - схему</a
              >
            </h6>
            <div v-if="visibleSheme === url.name">
              <div><a :href="url.download_url" target="blank">скачать</a></div>
              <InnerImageZoom
                v-for="i in shemeFotoUrl[url.name]"
                :key="i.id"
                :src="i.url"
                :zoomSrc="i.url"
              />
            </div>
          </div>
        </div>
        <div v-else class="text-center card" style="width: 50%;margin:auto;">
          <div class="card-body">
            <p>не удалось найти схему</p>
            <p>
              попробуйте поискать
              <a
                href="http://okzip.ru/index.php?resources/categories/СХЕМЫ-КАЛИБР.2/"
                target="blank"
                >тут</a
              >
            </p>
            <p>или обратитесь к своему менеджеру</p>
          </div>
        </div>
      </div>
      <div v-else />
    </div>
    <Loader v-if="loader" />
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
import Selected from "@/components/Selected.vue";
import ModalMessage from "@/components/ModalMessage.vue";
import { minLength } from "@/validations";
import UploadFile from "@/views/repairs/UploadFile.vue";
import SelectZapForRepair from "@/components/SelectZapForRepair.vue";
import { HTTPGET, HTTPPOSTFILE } from "@/api";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import InnerImageZoom from "vue-inner-image-zoom";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Loader from "@/components/Loader.vue";
export default {
  name: "AddNewRepair",
  computed: {
    ...mapGetters([
      "LIST_ZAP",
      "PLACEHOLDER",
      "DOWNLOADPERCENTAGE",
      "ZAPLOADING",
      "USERZAPORDER",
      "ZAP",
      "GET_PATH_FROM"
    ]),
    foo() {
      return this.dataInstr.dateRepair;
    },
    foo2() {
      return this.dataInstr.dateSale;
    },
    day() {
      return new Date(2021, 8, 25);
    }
  },
  watch: {
    foo() {
      const date1 = new Date(this.dataInstr.dateSale);
      const date2 = new Date(this.dataInstr.dateRepair);
      const date3 = new Date();
      if (date2 > date3) {
        this.dataInstr.dateRepair = null;
      }
      if (
        this.dataInstr.dateSale !== null &&
        this.dataInstr.dateRepair !== null
      ) {
        if (!(date1 <= date2)) {
          alert(
            "дата принятия в ремонт не может быть меньше чем дата продажи!"
          );
          this.dataInstr.dateRepair = null;
        }
      }
    },
    foo2() {
      const date1 = new Date(this.dataInstr.dateSale);
      const date2 = new Date(this.dataInstr.dateRepair);
      const date3 = new Date();
      if (date1 > date3) {
        this.dataInstr.dateSale = null;
      }
      if (
        this.dataInstr.dateSale !== null &&
        this.dataInstr.dateRepair !== null
      ) {
        if (!(date1 <= date2)) {
          alert("дата продажи не может быть больше чем дата ремонта!");
          this.dataInstr.dateSale = null;
        }
      }
    }
  },
  data: () => ({
    loader: false,
    disabled_serialNumberGroup: false,
    key: 1,
    downloadPercentage: "",
    uploadPercentage: "",
    saveButtonDisabled: false,
    nameInstr: "",
    dataZap: {
      data: null,
      loading: true,
      placeholder: ""
    },
    dataInstr: {
      cod: null,
      serialNumberGroup: "",
      serialNumber: "",
      notSerialNumber: false,
      dateSale: null,
      predtorg: false,
      dateRepair: null,
      seller: "",
      defect: "",
      typeOfRepair: "repair",
      noRepairType: null,
      noRepairTypeOther: null,
      workCarried: "",
      labelFoto: [],
      ticketFoto: [],
      viewFoto: [],
      defectDetailFoto: [],
      dataAdditionalFoto: [],
      receiptNumber: "",
      repairCategory: 1,
      comment: "",
      zapForRepair: [],
      userZapForRepair: [],
      noPhotoDefectDetailFoto: false
    },
    dateSaleDisabled: false,
    dateSaleInputAttr: {
      class: "form-control form-control-sm input-form shadow-none",
      placeholder: "дата продажи"
    },
    dateRepairInputAttr: {
      class: "form-control form-control-sm input-form shadow-none",
      placeholder: "дата принятия в ремонт"
    },
    validationsField: [
      {
        id: 0,
        name: "cod",
        validate: true,
        length: 1,
        result: false,
        error: false
      },
      {
        id: 1,
        name: "serialNumberGroup",
        validate: false,
        length: 0,
        result: false,
        error: false
      },
      {
        id: 2,
        name: "serialNumber",
        validate: true,
        length: 3,
        result: false,
        error: false
      },
      {
        id: 3,
        name: "dateSale",
        validate: true,
        length: 9,
        result: false,
        error: false
      },
      {
        id: 4,
        name: "dateRepair",
        validate: true,
        length: 9,
        result: false,
        error: false
      },
      {
        id: 5,
        name: "defect",
        validate: true,
        length: 3,
        result: false,
        error: false
      },
      {
        id: 6,
        name: "seller",
        validate: true,
        length: 3,
        result: false,
        error: false
      },
      {
        id: 7,
        name: "labelFoto",
        validate: true,
        length: 1,
        result: false,
        error: false
      },
      {
        id: 8,
        name: "ticketFoto",
        validate: true,
        length: 1,
        result: false,
        error: false
      },
      {
        id: 9,
        name: "viewFoto",
        validate: true,
        length: 1,
        result: false,
        error: false
      },
      {
        id: 10,
        name: "defectDetailFoto",
        validate: true,
        length: 1,
        result: false,
        error: false
      },
      {
        id: 11,
        name: "workCarried",
        validate: true,
        length: 1,
        result: false,
        error: false
      },
      {
        id: 12,
        name: "noRepairTypeOther",
        validate: false,
        length: 1,
        result: false,
        error: false
      }
    ],
    messageVisible: false,
    message: null,
    error: true,
    formKey: 1,
    shemeFotoUrl: null,
    no_photo_sheme: false,
    typeFileFoto: /\.(jpe?g|png|gif|jfif)$/i,
    typeFileAdditional: /\.(jpe?g|jfif|png|gif|avi|mp4|mov|mp3|xls|xlsx|pdf|docx|doc)$/i,
    visibleZap: true,
    textButtonSave: "сохранить",
    rout: "/",
    visibleSheme: false
  }),
  components: {
    Selected,
    DatePicker,
    UploadFile,
    ModalMessage,
    SelectZapForRepair,
    InnerImageZoom,
    Loader
  },
  methods: {
    ...mapActions(["ADDPATH"]),
    ...mapMutations(["DeleteInOrderUserZap"]),
    setNameInstr(name) {
      this.nameInstr = name;
      if (
        name.includes("БСЭ") &&
        name.includes("Бетоносмеситель") &&
        name.includes("Россия")
      ) {
        this.dataInstr.serialNumberGroup = "izhevsk";
        this.disabled_serialNumberGroup = true;
      } else {
        this.dataInstr.serialNumberGroup = "";
        this.disabled_serialNumberGroup = false;
      }
    },
    getVisibleSheme(name) {
      if (this.visibleSheme === name) {
        this.visibleSheme = false;
      } else {
        this.visibleSheme = name;
      }
    },
    radio(name) {
      this.dataInstr.typeOfRepair = name;
      if (name === "noRepair") {
        this.textButtonSave = "отправить на согласование";
      } else {
        this.textButtonSave = "сохранить";
      }
      if (name === "repair" || name === "diagnostiks") {
        this.validationsField[11].validate = true;
        this.validationsField[11].error = false;
        this.dataInstr.noRepairType = null;
      } else {
        this.validationsField[11].validate = false;
        this.validationsField[11].error = false;
        this.dataInstr.noRepairType = 1;
      }
      if (name === "repair") {
        this.visibleZap = true;
      } else {
        this.visibleZap = false;
      }
    },
    getNoPhotoDefectDetailFoto() {
      if (!this.dataInstr.noPhotoDefectDetailFoto) {
        this.validationsField[10].validate = false;
        this.validationsField[10].error = false;
      } else {
        this.validationsField[10].validate = true;
        this.validationsField[10].error = false;
      }
    },
    addZapForRepair(zap) {
      this.dataInstr.zapForRepair.push(zap);
    },
    setCodInstr(cod) {
      this.dataInstr.cod = cod;
      HTTPGET({
        url: "get_foto_sheme/",
        params: { cod: cod }
      })
        .then(response => (this.shemeFotoUrl = response.data))
        .then(response =>
          response
            ? (this.no_photo_sheme = false)
            : (this.no_photo_sheme = true)
        );
    },
    setFormDataLabelFoto(formdata) {
      this.dataInstr.labelFoto = formdata;
    },
    setFormDataTicketFoto(formdata) {
      this.dataInstr.ticketFoto = formdata;
    },
    setFormDataViewFoto(formdata) {
      this.dataInstr.viewFoto = formdata;
    },
    setFormDataDefectDetailFoto(formdata) {
      this.dataInstr.defectDetailFoto = formdata;
    },
    setFormDataAdditional(formdata) {
      this.dataInstr.dataAdditionalFoto = formdata;
    },
    hideMessage() {
      this.messageVisible = false;
      this.message = null;
    },
    disabledCheckbox() {
      const element = document.getElementById("serialNumberGroup");
      const element2 = document.getElementById("serialNumber");
      if (!this.dataInstr.notSerialNumber) {
        element.setAttribute("disabled", true);
        element2.setAttribute("disabled", true);
        this.dataInstr.serialNumberGroup = "";
        this.dataInstr.serialNumber = "";
        this.validationsField[1].validate = false;
        this.validationsField[2].validate = false;
        this.validationsField[1].error = false;
        this.validationsField[2].error = false;
      } else {
        element.removeAttribute("disabled");
        element2.removeAttribute("disabled");
        this.validationsField[1].validate = true;
        this.validationsField[2].validate = true;
        this.validationsField[1].error = false;
        this.validationsField[2].error = false;
      }
    },
    dateSaleDisable() {
      if (!this.dataInstr.predtorg) {
        this.dateSaleDisabled = true;
        this.dataInstr.dateSale = null;
        this.validationsField[3].validate = false;
        this.dateSaleInputAttr.class =
          "form-control form-control-sm input-form";
      } else {
        this.dateSaleDisabled = false;
        this.validationsField[3].validate = true;
      }
    },
    onSubmit() {
      this.error = false;
      if (this.dataInstr.noRepairType === "4") {
        this.validationsField[12].validate = true;
        this.validationsField[12].error = false;
      } else {
        this.validationsField[12].validate = false;
        this.validationsField[12].error = false;
      }
      this.validationsField.map(item => {
        if (item.validate) {
          item.result = minLength(this.dataInstr[item.name], item.length);
          if (!item.result) {
            item.error = true;
            item.name === "dateSale"
              ? (this.dateSaleInputAttr.class =
                  "form-control form-control-sm is-invalid")
              : null;
            item.name === "dateRepair"
              ? (this.dateRepairInputAttr.class =
                  "form-control form-control-sm is-invalid")
              : null;
            this.message =
              "все поля выделенные красным должны быть заполнены!!!";
            this.error = true;
          } else {
            item.error = false;
            item.name === "dateSale"
              ? (this.dateSaleInputAttr.class =
                  "form-control form-control-sm input-form")
              : null;
            item.name === "dateRepair"
              ? (this.dateRepairInputAttr.class =
                  "form-control form-control-sm input-form")
              : null;
          }
        }
      });
      this.error ? (this.messageVisible = true) : this.addNewRepair();
      //this.addNewRepair();
    },
    addNewRepair() {
      this.loader = true;
      this.saveButtonDisabled = true;
      let formData = new FormData();
      for (let i = 0; i < this.dataInstr.labelFoto.length; i++) {
        let file = this.dataInstr.labelFoto[i];
        formData.append(`labelFoto[${i}]`, file);
      }
      for (let i = 0; i < this.dataInstr.ticketFoto.length; i++) {
        let file = this.dataInstr.ticketFoto[i];
        formData.append(`ticketFoto[${i}]`, file);
      }
      for (let i = 0; i < this.dataInstr.viewFoto.length; i++) {
        let file = this.dataInstr.viewFoto[i];
        formData.append(`viewFoto[${i}]`, file);
      }
      for (let i = 0; i < this.dataInstr.defectDetailFoto.length; i++) {
        let file = this.dataInstr.defectDetailFoto[i];
        formData.append(`defectDetailFoto[${i}]`, file);
      }
      if (this.dataInstr.dataAdditionalFoto.length) {
        for (let i = 0; i < this.dataInstr.dataAdditionalFoto.length; i++) {
          let file = this.dataInstr.dataAdditionalFoto[i];
          formData.append(`dataAdditionalFoto[${i}]`, file);
        }
      }
      this.dataInstr.zapForRepair = this.ZAP;
      let data = {};
      for (const key in this.dataInstr) {
        if (!key.includes("Foto")) {
          data[key] = this.dataInstr[key];
        }
      }
      data = JSON.stringify(data);
      formData.append("data", data);
      HTTPPOSTFILE({
        url: "repairs/add_new_repair.php",
        data: formData,
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this)
      }).then(() => {
        if (this.dataInstr.typeOfRepair === "repair") {
          this.rout = "/repairs/repairs-list";
        }
        if (this.dataInstr.typeOfRepair === "noRepair") {
          this.rout = "/repairs/acts/acts-list";
        }
        if (this.dataInstr.typeOfRepair === "diagnostiks") {
          this.rout = "/repairs/diagnostics/diagnostics-list";
        }
        this.loader = false;
        this.$router.push(this.rout);
      });
    },
    back() {
      if (this.GET_PATH_FROM === "/login") {
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
    },
    heigtContent() {
      const heigtNavbar = document.getElementById("navbar").offsetHeight;
      const heigtButtonHead = document.getElementById("button-head")
        .offsetHeight;
      const height = window.innerHeight;
      const wrapper = document.getElementById("wrapper");
      wrapper.style.height = height - (heigtNavbar + heigtButtonHead) + "px";
    },
    translit() {
      this.dataInstr.serialNumberGroup = this.dataInstr.serialNumberGroup
        .replace("а", "a")
        .replace("А", "A")
        .replace("В", "B")
        .replace("в", "B")
        .replace("Е", "E")
        .replace("е", "e")
        .replace("К", "K")
        .replace("к", "k")
        .replace("М", "M")
        .replace("м", "m")
        .replace("Н", "H")
        .replace("н", "H")
        .replace("о", "o")
        .replace("О", "O")
        .replace("р", "p")
        .replace("с", "c")
        .replace("Т", "T")
        .replace("т", "T")
        .replace("у", "y")
        .replace("У", "Y")
        .replace("Р", "P")
        .replace("х", "x")
        .replace("С", "C")
        .replace("Х", "X")
        .replace("|", "/")
        .replace(/tosfi/i, "Tosfl")
        .replace("\\", "/");
    },
    translit2() {
      this.dataInstr.serialNumber = this.dataInstr.serialNumber.replace(
        "\\",
        "/"
      );
    }
  },
  mounted() {
    this.dataZap.data = this.LIST_ZAP;
    this.downloadPercentage = this.DOWNLOADPERCENTAGE;
    this.dataZap.loading = this.ZAPLOADING;
    this.dataZap.placeholder = this.PLACEHOLDER;
    this.ADDPATH("");
    this.dataInstr.userZapForRepair = this.USERZAPORDER;
    this.heigtContent();
  }
};
</script>
<style lang="scss" scoped>
.form-row {
  margin-right: 0;
}
select {
  text-align-last: center;
}
form {
  padding-top: 10px;
}
label {
  font-size: 0.875rem;
  color: #495057;
  margin-left: 10px;
  cursor: pointer;
}

.ml-10 {
  margin-left: 10px;
}
.mx-datepicker {
  display: block;
  width: auto;
  max-width: 200px;
}
small {
  text-align: center;
  margin: 0;
}
.formSpan {
  color: red;
}
.sheme {
  width: 100%;
}
.sheme-wrapper {
  height: 80vh;
  overflow: auto;
}
.table {
  font-size: 0.7em;
  margin: auto;
  margin-top: 10px;
}
.table td,
.table th {
  padding: 0.25rem;
  vertical-align: middle;
}
.bi-x {
  font-size: 2em;
  cursor: pointer;
  color: red;
}
.form-check-inline {
  border: 1px solid #ced4da;
  margin-top: 3px;
  padding: 3px;
}
.category {
  color: #6c757d;
  font-size: 0.9em;
}
.back {
  margin: 5px;
}
#wrapper {
  overflow: auto;
}
.left {
  padding-left: 10px;
}
</style>
<style>
.uploadfile-label {
  margin-left: 5px;
  font-size: 0.875rem;
}

.custom-file-label::after {
  display: none;
}
</style>
